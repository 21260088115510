@charset "utf-8";

* {
    margin: 0;
    padding: 0;
}

body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
button,
textarea,
p,
blockquote,
th,
td {
    margin: 0;
    padding: 0;
    font-family: "Helvetica Neue";
}

fieldset,
img {
    border: 0;
}

ul,
ol,
li {
    list-style: none;
    font-family: 'Helvetica Neue';
}

/* remember to define focus styles! */
:focus {
    outline: 0;
}

address,
caption,
cite,
code,
dfn,
em,
strong,
th,
var,
optgroup {
    font-style: normal;
    font-weight: normal;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-size: 100%;
    font-weight: normal;
}

abbr,
acronym {
    border: 0;
    font-variant: normal;
}

input,
button,
textarea,
select,
optgroup,
option {
    font-family: inherit;
    font-size: inherit;
    font-style: inherit;
    font-weight: inherit;
    background-color: #fff;
    border: none;
}

code,
kbd,
samp,
tt {
    font-size: 100%;
}

a {
    text-decoration: none;
    color: #000;
}

a:hover {
    text-decoration: none;
}

/*@purpose To enable resizing for IE */
/*@branch For IE6-Win, IE7-Win */
input,
button,
textarea {
    *font-size: 100%;
}

ol,
ul {
    list-style: none;
}

/* tables still need 'cellspacing="0"' in the markup */
table,
tr,
td {
    border-collapse: collapse;
    border-spacing: 0;
    margin: 0;
    padding: 0;
}

caption,
th {
    text-align: left;
}

sup,
sub {
    font-size: 100%;
    vertical-align: baseline;
}

/* remember to highlight anchors and inserts somehow! */
blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}

/**Common Css**/
.fr {
    float: right;
    *display: inline;
}

.fl {
    float: left;
    *display: inline;
}

/*文本超出显示省略号*/
.ehn,
.otw,
.tow {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

/*清除浮动*/
.clearfix {
    clear: both;
    *zoom: 1;
}

.clearfix:after {
    display: block;
    clear: both;
    content: "\0020";
    visibility: hidden;
    height: 0;
}

html,
body {
    font-family: 'Helvetica Neue';
    color: #000;
    font-weight: 500;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Optima';
    font-weight: 700;
}

h6 {
    font-size: 16px;
}

h5 {
    font-size: 20px;
}

h4 {
    font-size: 24px;
}

h3 {
    font-size: 32px;
}

h2 {
    font-size: 48px;
}

h1 {
    font-size: 60px;
}

.primary {
    color: #124EEB;
}

.success {
    color: #2DAF4A;
}

.error {
    color: #E43F29;
}

p{
    font-weight: 500;
}

span{
    font-weight: 400;
}

.flex{
    display: -webkit-box; /*  Safari, iOS, Android browser, older WebKit browsers. */
    display: -moz-box; /*Firefox (buggy) */
    display: -ms-flexbox; /*  IE 10 */
    display: -webkit-flex; /* Chrome 21+ */
    display: flex; /*  Opera 12.1, Firefox 22+ */
}
.alignItems{
    -webkit-box-align: center;
    -moz-align-items: center;
    -webkit-align-items: center;
    align-items: center;
}

.flex-wrap {
    flex-wrap: wrap;
}

.flex-row {
    flex-direction: row;
}
.flex-column{
    flex-direction: column;
}

.flex-col {
    flex-direction: column;
}

.flex-center-x {
    justify-content: center;
}
.flex-center-y {
    align-items: center;
}

.flex-space-x {
    justify-content: space-between;
}

.flex-space-a {
    justify-content: space-around;
}